import React from "react"
import { navigate } from 'gatsby';
import SEO from "../components/seo"
import { preloadImages } from "../helpers"
import Preloader from "../components/preloader/preloader"


let globalState, data, preloadData;

//const IndexPage = () => (
class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.preloaderRef = null;
    this.reqAnimFrame = null;

    
    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[0];
    preloadData = globalState.assets[0];


    // Wrap the require in check for window
    console.log("globalState ===")
    console.log(globalState);

    if (typeof window !== `undefined`) {
      this.state = {
        isActivated: false,
        styles: {
          top: window.innerHeight / 2,
          left: window.innerWidth / 2
        }
      }
    }
  }

  
  





  componentDidMount() {
    console.log("index.js component mounted");
    //console.log(globalState);

    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.watercolor, 
      globalState.cdnUrl + preloadData.titleImagine, 
      globalState.cdnUrl + preloadData.titleWould, 
      globalState.cdnUrl + preloadData.titleWelcome, 
      globalState.cdnUrl + preloadData.titleGoggles, 
      globalState.cdnUrl + preloadData.watertower, 
      globalState.cdnUrl + preloadData.richard, 
      globalState.cdnUrl + preloadData.brothers, 
      globalState.cdnUrl + preloadData.band, 
      globalState.cdnUrl + preloadData.goBtn, 
      globalState.cdnUrl + preloadData.planeVideoPoster,
      globalState.cdnUrl + preloadData.planeVideoPoster,
    ], this.preloadCallback);

  }

  initIntro = () => {
    
    //Set isActivated locally and globally
    this.setState({ isActivated: true });
    globalState.updateIsActivated(true);

    //this.preloadCallback(0);

    setTimeout(() => {
      if(this.state.isActivated){
        navigate('/intro/');
      }
    }, globalState.showPrevNextDelay);
    
  }

  preloadCallback = (delay = 1000) => {
    console.log("images are all loaded");
    this.refs.preloaderRef.showPlayBtn();
  }

  


  render() {

    
    return (
      <>
      <link rel="prefetch" href="/intro" />
      <link rel="preload" as="video" href={globalState.videoUrl + preloadData.planeVideo} />
      
        <div className="fullscreen-bg black">
          <SEO title="NFB - Welcome to Pine Point" />
          {/* <div className="preloader" ref={div => this.preloaderRef = div} style={this.state.styles} /> */}
          <Preloader ref="preloaderRef" preload={true} globalState={globalState} data={data} callback={this.initIntro} />    
        </div>

      </>
    );
  }
}


export default IndexPage
